import { Link, useLoaderData } from "@remix-run/react";
import cx from "classnames";
import lodash from "lodash";
import { ChevronRight, CircleX, Search as SearchIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import Accordion from "~/components/Accordion";
import Container from "~/components/Container";
import contents from "~/constants/contents";
import replaceWithDash from "~/utils/replaceWithDash";

const ServiceRow = ({ service, i18n }) => {
  const onImgError = (e) => {
    e.target.onerror = null;
    e.target.src = `https://s3.hidsim.com/services/other.svg`;
  };

  return (
    <Link
      key={service._id}
      to={`/services/${service._id}/${replaceWithDash(service.name.common.toLowerCase())}`}
      className="flex justify-start ps-4 align-middle gap-3 rounded-xl bg-base-200"
    >
      <div className="flex justify-start items-center">
        <img
          onError={onImgError}
          src={`https://s3.hidsim.com/services/${service.name.a2}.svg`}
          className="h-9 w-9 rounded-full"
        />
      </div>

      <div className="flex justify-between items-center grow pe-5">
        <div className="flex flex-col justify-center align-middle font-semibold grow py-5">
          {service.name.common}
        </div>
        <div className="flex justify-center items-center">
          <ChevronRight
            size={20}
            className={cx({
              "rotate-180": i18n.dir() === "rtl",
            })}
          />
        </div>
      </div>
    </Link>
  );
};

function ServicesDesktop({ textQuery, setTextQuery }) {
  const { services, other } = useLoaderData<typeof loader>();
  const { t, i18n } = useTranslation("translation");

  return (
    <section className="py-28 bg-base-200">
      <Container className="rounded-2xl p-12 px-12 grid grid-cols-1 md:grid-cols-5 gap-20 bg-white max-w-7xl">
        <div className="md:col-span-2">
          <div className="relative w-4/5 rounded-full bg-base-200 focus:outline-none flex gap-1 items-center py-4">
            <SearchIcon className="text-black ms-4" strokeWidth={2} />
            <input
              type="text"
              placeholder={t("searchServices")}
              className="shrink-0 grow basis-0 items-center rounded-full bg-base-200 px-1 focus:outline-none w-full leading-none"
              onChange={(e) => setTextQuery(e.target.value)}
              value={textQuery}
            />
            {textQuery && (
              <button
                onClick={() => setTextQuery("")}
                className={cx(
                  "absolute top-4 flex h-6 w-6 items-center justify-center text-center text-3xl leading-none text-neutral-500",
                  {
                    "right-4": i18n.dir() === "ltr",
                    "left-4": i18n.dir() === "rtl",
                  },
                )}
              >
                <CircleX strokeWidth={1} />
              </button>
            )}
          </div>

          <div className="border-b border-base-200/70 mt-4" />

          <div className="my-4 flex flex-col gap-2">
            {!lodash.isEmpty(services) &&
              services?.results?.map((service, index) => (
                <ServiceRow service={service} index={index} i18n={i18n} />
              ))}

            {lodash.isEmpty(services?.results) && (
              <Link
                to={`/services/${other._id}/${replaceWithDash(other.name.common.toLowerCase())}`}
                className="flex rounded-lg bg-base-200 p-3 align-middle"
              >
                <img
                  src={`https://s3.hidsim.com/services/${other.name.a2}.svg`}
                  className="h-8 w-8 rounded-full"
                />
                <div className="mx-2 block whitespace-nowrap overflow-ellipsis overflow-x-hidden max-w-48 md:max-w-52 xl:max-w-56">
                  {other.name.common}
                </div>
              </Link>
            )}
          </div>
        </div>
        <div className="md:col-span-3">
          <div className="flex flex-col gap-8">
            <h1 className="text-neutral-900 text-[40px] font-bold font-['Hubot-Sans','IRANYekanXVF'] leading-[64px]">
              {t("home.title")}
            </h1>
            <h2 className="text-neutral-900 text-base font-normal font-['Figtree', 'IRANYekanXVF'] leading-7">
              {t("home.description")}
            </h2>

            <div className="justify-start items-start gap-5 grid grid-cols-2 border-b border-base-200/70 pb-8">
              {contents.benefits.map((b) => {
                if (
                  i18n.language !== "fa" &&
                  b.name === "home.benefits.visaAndCreditCardAccepted"
                )
                  return null;
                return (
                  <div className="p-5 bg-[#e0f7e8] rounded-lg flex-col justify-start items-center gap-5 flex">
                    <div className="p-3 bg-[#c2efd1] rounded-full justify-center items-center gap-2 flex">
                      <b.Icon />
                    </div>
                    <div className="text-center text-neutral-900 text-base font-semibold font-['Hubot-Sans', 'IRANYekanXVF'] leading-normal">
                      {t(b.name)}
                    </div>
                  </div>
                );
              })}
            </div>

            <div>
              <h2 className="text-neutral-900 text-[40px] uppercase font-bold font-['Hubot-Sans', 'IRANYekanXVF'] leading-[64px]">
                {t("faq")}
              </h2>

              <div className="flex items-start justify-center gap-6 mt-5">
                <div className="z-10 flex flex-col items-center justify-center gap-3">
                  {contents?.faq.map((f) => (
                    <Accordion key={f.q} title={t(f.q)} description={t(f.a)} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default ServicesDesktop;
