import { type LoaderFunctionArgs, type MetaFunction } from "@remix-run/node";
import { Link, json, useLoaderData, useNavigate } from "@remix-run/react";
import { useDebounce } from "@uidotdev/usehooks";
import qs from "qs";
import React from "react";
import { useTranslation } from "react-i18next";
import type { SitemapFunction } from "remix-sitemap";
import * as cookies from "~/cookies";
import { fallbackLng, supportedLngs } from "~/i18n";
import i18nServer from "~/i18n.server";
import ServicesScreen from "~/screens/services.index";
import { getListResources, getLookupResource } from "~/services/api";

export async function loader({ params, request }: LoaderFunctionArgs) {
  let cookie = await cookies.auth.find(request);
  const locale = await i18nServer.getLocale(request);
  const inUrlQs = {
    limit: 30,
    skip: 0,
    ...qs.parse(request.url.split("?")[1]),
  };
  const text = inUrlQs.text;
  let promises = [];
  const preferredLng = locale || cookie?.user?.settings?.language;
  const headers = { "axios-to-fetch": 1 };
  headers["preferred-lng"] = preferredLng;
  const projection = "name _id id translations";

  if (text) {
    promises.push(
      getListResources({
        token: cookie?.token,
        query: {
          text,
          status: "available",
          limit: inUrlQs.limit,
          skip: inUrlQs.skip,
          projection,
        },
        resource: "services",
        headers,
      }),
    );
  } else {
    promises.push(
      getListResources({
        token: cookie?.token,
        query: {
          status: "available",
          sort: { popularity: -1 },
          limit: inUrlQs.limit,
          skip: inUrlQs.skip,
          projection,
        },
        resource: "services",
        headers,
      }),
    );
  }

  promises.push(
    getLookupResource({
      token: cookie?.token,
      query: {
        "name.a2": "other",
        projection,
      },
      resource: "services",
      headers,
    }),
  );

  const [services, other] = await Promise.all(promises);

  return json({
    services: services.data,
    other: other.data,
    inUrlQs,
    cookie,
  });
}

export const meta: MetaFunction = () => {
  const { t, i18n } = useTranslation("translation");
  const canonicalUrl = `https://hidsim.com/services/?lng=${i18n.language}`;
  const baseUrl = "https://hidsim.com/services";

  return [
    {
      title: t("services") + " | " + t("title"),
    },
    {
      name: "description",
      content: t("description"),
    },
    {
      property: "og:title",
      content: t("services") + " | " + t("og.title"),
    },
    {
      property: "og:description",
      content: t("og.description"),
    },
    {
      property: "og:url",
      content: "https://hidsim.com",
    },
    {
      property: "og:image",
      content: "/img/open_graph_logo.png",
    },
    {
      property: "og:image:width",
      content: "1200",
    },
    {
      property: "og:image:height",
      content: "630",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:site_name",
      content: t("og.site_name"),
    },
    {
      property: "twitter:card",
      content: "summary_large_image",
    },
    {
      property: "twitter:image",
      content: "/img/twitter_graph_logo.png",
    },
    ...[
      {
        tagName: "link",
        rel: "canonical",
        href: canonicalUrl,
        key: baseUrl,
      },
      {
        tagName: "link",
        rel: "alternate",
        hrefLang: "x-default",
        href: `${baseUrl}/?lng=${fallbackLng}`,
        key: baseUrl + "x-default",
      },
      ...supportedLngs.map((lng) => ({
        tagName: "link",
        rel: "alternate",
        hrefLang: lng,
        href: baseUrl + `/?lng=${lng}`,
        key: baseUrl + `/?lng=${lng}`,
      })),
    ],
  ];
};

export const sitemap: SitemapFunction = async ({ config, request }) => {
  const services = await getListResources({
    token: null,
    query: {
      status: "available",
      sort: { popularity: -1 },
      limit: 20000,
      skip: 0,
    },
    resource: "services",
  });
  const baseUrl = config.siteUrl + "/services";

  return services.data.results.map((inLoopService, index) => ({
    changefreq: "monthly",
    priority: index < 100 ? 1.0 : 0.1,
    loc: `/services/${inLoopService._id}`,
    // acts only in this loc
    alternateRefs: supportedLngs.map((lng) => ({
      href: `${baseUrl}/${inLoopService._id}/?lng=${lng}`,
      absolute: true,
      hrefLang: lng,
    })),
  }));
};

function Services() {
  const { inUrlQs } = useLoaderData<typeof loader>();
  const [textQuery, setTextQuery] = React.useState(inUrlQs.text || "");
  const navigate = useNavigate();
  const debouncedTextQuery = useDebounce(textQuery, 1000);

  React.useEffect(() => {
    const currenQs = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    const currentTextQuery = currenQs.text || "";
    if (currentTextQuery === debouncedTextQuery) return;
    if (!debouncedTextQuery) navigate("/services/");
    else
      navigate("/services/" + "?" + qs.stringify({ text: debouncedTextQuery }));
  }, [debouncedTextQuery]);

  return <ServicesScreen textQuery={textQuery} setTextQuery={setTextQuery} />;
}

export default Services;
