import { UserAgent } from "@quentin-sommer/react-useragent";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

function ServicesScreen(props) {
  return (
    <section>
      <UserAgent computer>
        <Desktop {...props} />
      </UserAgent>

      <UserAgent tablet>
        <Desktop {...props} />
      </UserAgent>

      <UserAgent mobile>
        <Mobile {...props} />
      </UserAgent>
    </section>
  );
}

export default ServicesScreen;
